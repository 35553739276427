import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Age } from '../../Form/Age'
import { AgreeList } from '../../Form/AgreeList'
import {
  B2BPromocode,
  InternationalTelephone,
  Name,
  WorkEmail
} from '../../Form/Input'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from '../../RegistrationModals/FormButton'
import {
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  Title
} from '../../RegistrationModals/index.styles'
import { size } from '../../../constants'

import { Captcha } from '../../Form/Captcha'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../../../atoms/useFormValues'
import { subscription } from '../vars'
import { useB2BRegistrationContext } from '../hooks/useB2BRegistrationContext'
import { useSaveReferalParameter } from '../../../atoms/useSaveReferalParameter'

const FormGridNameAge = styled.div`
  display: grid;
  grid-template-columns: auto 180px;
  grid-gap: 24px;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const Form = styled(({ className, handleSubmit, restart, errors, values }) => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])
  const { updateContext } = useB2BRegistrationContext()
  const onChangeCountry = useCallback(
    (country) => updateContext({ country }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <FormMain
      noValidate
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues: values
      })}
    >
      <FormWrapWithoutButton>
        <FormHeader>
          <Title bold>Регистрация для b2b клиентов</Title>
          <Paragraph>
            Создайте аккаунт, чтобы начать заниматься с психологом
          </Paragraph>
        </FormHeader>
        <FormItems>
          <FormGridNameAge>
            <Name />
            <Age />
          </FormGridNameAge>
          <InternationalTelephone onChangeCountry={onChangeCountry} />
          <WorkEmail />
          <B2BPromocode />
          <AgreeList />
          <Captcha />
          <ModalButtonGroup>
            <FormButton>Зарегистрироваться</FormButton>
          </ModalButtonGroup>
        </FormItems>
      </FormWrapWithoutButton>
    </FormMain>
  )
})``

export const IdentityStepForm = ({ onSubmit }) => {
  const { formData } = useB2BRegistrationContext()
  const [promocode] = useSaveReferalParameter('pt_promocode')

  return (
    <FinalForm
      initialValues={{
        agreement: true,
        tel: formData.tel,
        email: formData.email,
        name: formData.name,
        age: formData.age,
        promocode: promocode
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { restart }, errors, values }) => (
        <Form
          errors={errors}
          handleSubmit={handleSubmit}
          restart={restart}
          values={values}
        />
      )}
      subscription={subscription}
      validate={() => {}}
    />
  )
}
